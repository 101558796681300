<template>
  <div class="analysis-course">
    <!-- 日期选择 -->
    <div class="analysis-header rank-plate">
      <analyze-date-range
        @change="onChangeDateRange"
        :defaultDateRange="$store.state.otherParams"
        @changeSchool="onChangeSchool"
      ></analyze-date-range>
    </div>
    <!-- 教师备授课排名 -->
    <div class="rank-plate">
      <prepare-teaching-rank
        :other-params="otherParams"
      ></prepare-teaching-rank>
    </div>
    <!-- 年级课程备课分析 -->
    <div class="rank-plate">
      <grade-subject-analyze
        :other-params="otherParams"
      ></grade-subject-analyze>
    </div>
    <!-- 年级课程授课分析 -->
    <div class="rank-plate">
      <grade-subject-analyze
        :other-params="otherParams"
        :use-type="1"
      ></grade-subject-analyze>
    </div>
  </div>
</template>

<script>
import { genQuickDateRange } from "@/utils";
import analyzeDateRangeVue from "@/components/teaching-components-chart/analyze-date-range/index.vue";
import prepareRankVue from "./children/prepare-teaching-rank.vue";
import gradeSubjectAnalyzeVue from "./children/grade-subject-analyze.vue";

export default {
  name: "course",
  // 注册组件
  components: {
    [analyzeDateRangeVue.name]: analyzeDateRangeVue, // 日期范围组件
    [prepareRankVue.name]: prepareRankVue, // 备课排行组件
    [gradeSubjectAnalyzeVue.name]: gradeSubjectAnalyzeVue, // 年级学科分析组件
  },
  directives: {},
  data() {
    return {
      /**
       * 其他参数
       */
      otherParams: {
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    /**
     * 是否显示echart柱状图
     */
    isShowEchartBar() {
      return ["before", "after"].indexOf(this.rankType) !== -1;
    },
  },
  created() {
    // 初始化默认值
    console.log("初始化默认值==========", this.$store.state.otherParams);
    console.log("初始化默认值otherParams==========", this.otherParams);
    if (
      this.$store.state.otherParams &&
      this.$store.state.otherParams.length === 2
    ) {
      console.log("111111==========");
      this.onChangeDateRange(this.$store.state.otherParams);
    }
    // if (this.$store.state.otherParams) {
    //   console.log(
    //     "this.$store.state.otherParams=====",
    //     this.$store.state.otherParams
    //   );
    //   if (isNaN(this.$store.state.otherParams)) {
    //     console.log("111111");
    //     this.onChangeDateRange(this.$store.state.otherParams);
    //   } else {
    //     console.log("2222222");
    //     this.onChangeDateRange(
    //       genQuickDateRange(this.$store.state.otherParams)
    //     );
    //   }
    // } else {
    //   console.log("genQuickDateRange=====");
    //   this.onChangeDateRange(genQuickDateRange(7));
    // }
  },
  methods: {
    /**
     * 时间范围改变
     */
    onChangeDateRange(data) {
      // 校验数据
      if (!data && data.length > 2) return console.warn("参数错误");
      let params = {
        startDate: this.$dateFormat(data[0], "yyyy-MM-dd"),
        endDate: this.$dateFormat(data[1], "yyyy-MM-dd"),
      };
      console.log("onChangeDateRange======");
      if (this.$store.state.schoolCode) {
        params.tenantId = this.$store.state.schoolCode;
      }
      this.otherParams = params;
    },
    /**
     * 学校 选择
     */
    onChangeSchool(schoolCode) {
      this.otherParams = {
        ...this.otherParams,
        tenantId: schoolCode, // 学校编码
      };
      this.$emit("changeSchool", schoolCode);
    },
  },
};
</script>

<style lang="less" scoped>
.analysis-header {
  background: #fff;
  padding: 10px 0;
  margin-bottom: 10px;
  border-radius: 4px 4px 0px 0px;
}
.rank-plate {
  background: #fff;
  border-radius: 4px;
  & + .rank-plate {
    margin-top: 10px;
  }
}
</style>
