<template>
  <div v-loading="allLoading" class="prepare-rank">
    <header class="prepare-header flex flex-b flex-m">
      <analysis-title before-icon="icon-tit-bar">
        {{ $t("el.dataAnalysis.teachingDailyRacking") }}
        <div slot="content">
          <p
            v-for="(item, index) in $t(
              'el.dataAnalysis.teachingDailyRackingDesc'
            )"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      </analysis-title>
      <choose-type
        class="choose-type"
        style="margin-right: 20px; margin-left: auto"
        :choose-options="statisticalMethodOptions"
        :choose-type="statisticalMethod"
        @changeChooseType="(val) => (statisticalMethod = val)"
      >
      </choose-type>
      <choose-type
        class="choose-type"
        :choose-options="chooseOptions"
        :choose-type="rankType"
        @changeChooseType="(val) => (rankType = val)"
      >
      </choose-type>
    </header>
    <div style="margin-top: 20px; text-align: left">
      <span style="font-size: 14px; margin-left: 30px">{{
        $t("el.dataAnalysis.selectCoursesToAnalysis") +
        $t("el.common.colon") +
        " "
      }}</span>
      <el-select
        v-model="courseId"
        clearable
        filterable
        :placeholder="$t('el.common.pleaseSelect')"
        :popper-append-to-body="false"
        @change="onHandleCourseChange"
      >
        <el-option
          v-for="item in courseList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div
      style="margin-top: 20px; text-align: right"
      v-show="rankType === 'all'"
    >
      <el-button
        style="margin-right: 20px"
        type="primary"
        size="medium"
        @click="onHandleDownLoadTable()"
        >{{ $t("el.schoolResourceManage.derivedForm") }}</el-button
      >
    </div>

    <main>
      <div class="echarts-warpper" v-if="isShowEchartBar">
        <bar-rank
          :data="barData"
          v-loading="rankLastLoadId"
          @onHandleChart="onHandleChart"
        ></bar-rank>
      </div>
      <template v-else>
        <template v-if="pagingLastLoadId || (tableData && tableData.length)">
          <el-table v-loading="pagingLastLoadId" border :data="tableData">
            <el-table-column
              :label="$t('el.authDialog.name')"
              prop="staffName"
            ></el-table-column>
            <el-table-column
              :label="$t('el.forgotPwd.MobileNumber')"
              prop="staffPhone"
            ></el-table-column>
            <el-table-column
              :label="
                $t('el.dataAnalysis.preparationDailyTime') +
                '（' +
                $t('el.MyHome.minutes') +
                '）'
              "
              prop="prepareTime"
            ></el-table-column>
            <el-table-column
              :label="
                $t('el.dataAnalysis.teachingDailyTime') +
                '（' +
                $t('el.MyHome.minutes') +
                '）'
              "
              prop="teachTime"
            ></el-table-column>
            <el-table-column
              v-if="statisticalMethod === '1'"
              :label="$t('el.common.operate')"
            >
              <template slot-scope="data">
                <el-button type="text" @click="toHandleChart(data.row)">{{
                  $t("el.courseDetails.details")
                }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="pagingParams.page"
            :page-size="pagingParams.limit"
            :total="total"
            @size-change="getPagingData"
            layout="prev, pager, next, slot, total"
          >
            <span class="pageTotal"
              >{{ $t("el.pagination.totalname") }}{{ pages
              }}{{ $t("el.pagination.pages") }}</span
            >
          </el-pagination>
        </template>
        <not-data v-else></not-data>
      </template>
    </main>

    <el-dialog
      width="950px"
      :title="staffName + ' ' + $t('el.dataAnalysis.DetailsOfCourse')"
      :visible.sync="dialogStackVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      custom-class="details-custom"
    >
      <div class="details-time">
        {{
          otherParams.startDate.replace(/-/g, "/") +
          "-" +
          otherParams.endDate.replace(/-/g, "/")
        }}
      </div>
      <bar-stack
        v-if="dialogStackVisible"
        :data="barDetailData"
        v-loading="rankLastLoadId"
      ></bar-stack>
    </el-dialog>
  </div>
</template>

<script>
import analysisTitleVue from "@/components/teaching-components-chart/analysis-title/index.vue";
import chooseTypeVue from "@/components/teaching-components-chart/choose-type/index.vue";
import notDataVue from "@/components/teaching-components-chart/not-data/index.vue";
import barRank from "@/components/teaching-components-chart/bar-rank/index.vue";
import barStack from "@/components/teaching-components-chart/bar-stack/index.vue";
import { exportDownCommon } from "../../../../../utils";
import { handparams } from "../../../../../utils";

let ajaxUid = 0;

export default {
  name: "prepare-teaching-rank",
  components: {
    [analysisTitleVue.name]: analysisTitleVue, // 报表标题
    [chooseTypeVue.name]: chooseTypeVue, // 单选组件
    [notDataVue.name]: notDataVue, // 无数据
    [barRank.name]: barRank, // 柱状图排行
    [barStack.name]: barStack, // 堆叠柱状图
  },
  props: {
    /**
     * 其他查询参数
     */
    otherParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rankType: "before", // 显示报表类型
      avgAllTime: 0, // 全校日均备授课时间
      avgPrepareTime: 0, // 全校日均备课时间
      avgTeachTime: 0, // 全校日均授课时间
      xAxisData: [], // y轴老师数据列表
      useTimePageRecords: [], // y轴老师全部数据列表
      prepareYAxisData: [], // 备课日均时间数据
      teachingYAxisData: [], // 授课日均时间数据
      avgAllTimeDetails: 0, // 个人日均备授课时间
      avgPrepareTimeDetails: 0, // 个人日均备课时间
      avgTeachTimeDetails: 0, // 个人日均授课时间
      xAxisDataDetails: [], // y轴个人数据列表
      useTimePageRecordsDetails: [], // y轴个人数据列表
      prepareYAxisDataDetails: [], // 备课日均时间数据
      teachingYAxisDataDetails: [], // 授课日均时间数据

      rankLastLoadId: 0, // 排名加载数据最后一次调用id
      pagingLastLoadId: 0, // 分页加载数据最后一次调用id
      tableData: [], // 列表数据
      statisticalMethod: "1", // 0 是日均， 1 是累计
      pagingParams: {
        // 分页参数
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0, // 总记录数
      courseId: "",
      courseList: [],
      staffName: "",
      staffCode: "",
      dialogStackVisible: false,
    };
  },
  computed: {
    /**
     * 是否显示echart柱状图
     */
    isShowEchartBar() {
      return ["before", "after"].indexOf(this.rankType) !== -1;
    },
    /**
     * 柱状图数据
     */
    barData() {
      return {
        avgAllTime: this.avgAllTime,
        avgPrepareTime: this.avgPrepareTime,
        avgTeachTime: this.avgTeachTime,
        xAxisData: this.xAxisData,
        prepareYAxisData: this.prepareYAxisData,
        teachingYAxisData: this.teachingYAxisData,
      };
    },

    /**
     * 柱状图数据
     */
    barDetailData() {
      console.log("barDetailData====", this.prepareYAxisDataDetails);
      return {
        avgAllTime: this.avgAllTimeDetails,
        avgPrepareTime: this.avgPrepareTimeDetails,
        avgTeachTime: this.avgTeachTimeDetails,
        xAxisData: this.xAxisDataDetails,
        prepareYAxisData: this.prepareYAxisDataDetails,
        teachingYAxisData: this.teachingYAxisDataDetails,
      };
    },

    /**
     * 总页数
     */
    pages() {
      return Math.ceil(this.total / this.pagingParams.limit);
    },

    statisticalMethodOptions() {
      return [
        {
          value: this.$t("el.common.accumulate"),
          label: "1",
        },
        {
          value: this.$t("el.common.averageDaily"),
          label: "0",
        },
      ];
    },

    chooseOptions() {
      return [
        {
          value: this.$t("el.dataAnalysis.topTen"),
          label: "before",
        },
        {
          value: this.$t("el.dataAnalysis.afterRanking10"),
          label: "after",
        },
        {
          value: this.$t("el.schoolResourceManage.all"),
          label: "all",
        },
      ];
    },
  },
  watch: {
    /**
     * 其他参数
     */
    otherParams: {
      handler: "getData",
    },
    /**
     * 监控显示什么报表
     */
    rankType: {
      handler: "getData",
    },
    statisticalMethod: {
      handler: "getData",
    },
    /**
     * 监听分页数，动态计算offset
     */
    "pagingParams.page"(newVal, oldVal) {
      let { limit = 10, page = 1 } = this.pagingParams;
      this.pagingParams.offset = limit * (page - 1);
      this.getPagingData();
    },
  },
  created() {
    this.getData();
    this.getCourseList();
  },
  mounted() {
    // 绑定事件
    // window.addEventListener('resize', this.onResize);
    // this.$on('hook:destroyed', () => {
    //   window.removeEventListener('resize', this.onResize);
    // });
  },
  methods: {
    /**
     * 窗口变化
     * 重绘echarts
     */
    // onResize () {
    //   if (!this.isShowEchartBar) return;
    //   this.$nextTick(() => {
    //     this.$refs.echarts && this.$refs.echarts.chart && this.$refs.echarts.chart.resize();
    //   });
    // },
    /**
     * 获取数据
     * @param {*} 调用参数
     */
    getTeacherUseTimeData(params) {
      let { otherParams } = this; // 获取其他参数
      let data = handparams({
        // 默认参数
        limit: 10,
        offset: 0,
        page: 1,
        pages: 1,
        courseId: this.courseId,
        ...otherParams,
        ...params,
      });
      return this.$axios.get("/bi/detailUse/teacher/useTime", {
        params: data,
      });
    },

    // 教师详情备授课数据
    getTeacherUseTimeDataDetails(params) {
      let { otherParams } = this; // 获取其他参数
      let data = handparams({
        // 默认参数
        // limit: 10,
        // offset: 0,
        // page: 1,
        // pages: 1,
        courseId: this.courseId,
        staffCode: this.staffCode,
        ...otherParams,
        ...params,
      });
      return this.$axios.get("/bi/detailUse/teacher/useTime/detail", {
        params: data,
      });
    },

    // 获取图表详情数据
    getEchartDetailBarData() {
      this.xAxisDataDetails = [];
      return this.getTeacherUseTimeDataDetails({
        // descent: this.rankType === "after" ? 1 : 0, // 0：前十名，1：后十名
        // statisticalMethod: this.statisticalMethod,
      }).then(({ data }) => {
        // 处理数据赋值
        data = data || {};
        this.avgPrepareTimeDetails = data.prepareTime || 0;
        this.avgTeachTimeDetails = data.teachTime || 0;
        this.avgAllTimeDetails = data.allUseTime || 0;
        let xAxisDataDetails = [];
        let prepareYAxisDataDetails = [];
        let teachingYAxisDataDetails = [];
        data.useTimePage &&
          Array.isArray(data.useTimePage.records) &&
          data.useTimePage.records.forEach((item) => {
            xAxisDataDetails.push(item.createTime); // 时间为 x 轴
            prepareYAxisDataDetails.push(item.prepareTime || null);
            teachingYAxisDataDetails.push(item.teachTime || null);
          });
        data.useTimePage &&
          Array.isArray(data.useTimePage.records) &&
          (this.useTimePageRecordsDetails = data.useTimePage.records);
        this.xAxisDataDetails = xAxisDataDetails;
        this.prepareYAxisDataDetails = prepareYAxisDataDetails;
        this.teachingYAxisDataDetails = teachingYAxisDataDetails;
      });
    },

    async onHandleDownLoadTable() {
      let { otherParams } = this;
      console.log("otherParams====", otherParams);
      if (!(otherParams.startDate && otherParams.endDate)) return; // 无日期参数不调用
      let id = ++ajaxUid;
      this.pagingLastLoadId = id;
      let params = {
        ...this.pagingParams,
        ...otherParams,
        courseId: this.courseId,
        statisticalMethod: this.statisticalMethod,
        descent: 1, // 固定排序正序
      };
      try {
        await exportDownCommon(
          "get",
          handparams(params),
          "/bi/export/detailUse/teacher/useTime",
          `${this.$t("el.schoolResourceManage.teacher")}${
            this.statisticalMethod === "0"
              ? this.$t("el.common.averageDaily")
              : this.$t("el.common.accumulate")
          }${this.$t("el.board.RankingLesson")}_${new Date().getTime()}.xlsx`
        ).finally(() => {
          if (id === this.pagingLastLoadId) this.pagingLastLoadId = null;
        });
      } catch (error) {
        //
        if (id === this.pagingLastLoadId) this.pagingLastLoadId = null;
        console.log("error==", error);
      }
    },

    // 选择需分析的课程 change
    onHandleCourseChange() {
      this.getData();
    },
    // 获取课程列表接口
    getAuthCourses(courseName) {
      return this.$axios.get("/courseManage/getAllCourse", {
        params: {
          // 默认参数
          courseName,
        },
      });
    },
    getCourseList() {
      return this.getAuthCourses()
        .then((result) => {
          this.courseList = result.data.map((item) => {
            item.value = item.courseId;
            item.label = item.courseName;
            return item;
          });
        })
        .catch(() => {
          this.courseList = [];
        });
    },

    /**
     * 获取图表数据
     */
    getEchartBarData() {
      if (!this.isShowEchartBar) return;
      let id = ++ajaxUid;
      this.rankLastLoadId = id;
      return this.getTeacherUseTimeData({
        descent: this.rankType === "after" ? 1 : 0, // 0：前十名，1：后十名
        statisticalMethod: this.statisticalMethod,
      })
        .then(({ data }) => {
          if (id !== this.rankLastLoadId) return; // 判断是否是最后一次有效调用接口
          // 处理数据赋值
          data = data || {};
          this.avgPrepareTime = data.prepareTime || 0;
          this.avgTeachTime = data.teachTime || 0;
          this.avgAllTime = data.allUseTime || 0;
          let xAxisData = [];
          let prepareYAxisData = [];
          let teachingYAxisData = [];
          data.useTimePage &&
            Array.isArray(data.useTimePage.records) &&
            data.useTimePage.records.forEach((item) => {
              xAxisData.push(item.staffName);
              prepareYAxisData.push(item.prepareTime || null);
              teachingYAxisData.push(item.teachTime || null);
            });
          data.useTimePage &&
            Array.isArray(data.useTimePage.records) &&
            (this.useTimePageRecords = data.useTimePage.records.reverse());

          this.xAxisData = xAxisData.reverse();
          this.prepareYAxisData = prepareYAxisData.reverse();
          this.teachingYAxisData = teachingYAxisData.reverse();
        })
        .finally(() => {
          if (id === this.rankLastLoadId) this.rankLastLoadId = null;
        });
    },
    /**
     * 获取分页数据
     */
    getPagingData() {
      if (this.isShowEchartBar) return;
      let id = ++ajaxUid;
      this.pagingLastLoadId = id;
      return this.getTeacherUseTimeData({
        ...this.pagingParams,
        statisticalMethod: this.statisticalMethod,
        descent: 1, // 固定排序正序
      })
        .then(({ data }) => {
          if (id !== this.pagingLastLoadId) return; // 判断是否是最后一次有效调用接口
          // 处理数据
          let pagingData = data.useTimePage;
          let isNullData = !data.useTimePage;
          if (isNullData) console.warn("接口返回空数据");
          this.total = (!isNullData && data.useTimePage.total) || 0;
          this.tableData =
            (!isNullData &&
              Array.isArray(data.useTimePage.records) &&
              data.useTimePage.records) ||
            [];
        })
        .finally(() => {
          if (id === this.pagingLastLoadId) this.pagingLastLoadId = null;
        });
    },

    // 去详情
    async toHandleChart(row) {
      this.staffCode = row.userId;
      this.staffName = row.staffName;
      this.dialogStackVisible = true;

      try {
        await this.getEchartDetailBarData();
      } catch (error) {
        //
      }

      this.dialogStackVisible = true;
    },

    // Chart 点击事件
    async onHandleChart(params, myChart) {
      if (this.statisticalMethod === "0") {
        return;
      }
      let pointInPixel = [params.offsetX, params.offsetY];
      if (myChart.containPixel("grid", pointInPixel)) {
        // 点击第几个柱子
        let pointInGrid = myChart.convertFromPixel(
          { seriesIndex: 0 },
          pointInPixel
        );
        // console.log(pointInGrid)
        // 也可以通过params.offsetY 来判断鼠标点击的位置是否是图表展示区里面的位置
        // 也可以通过name[xIndex] != undefined，name是x轴的坐标名称来判断是否还是点击的图表里面的内容
        // x轴数据的索引
        let xIndex = pointInGrid[0];
        // y轴数据的索引
        let yIndex = pointInGrid[1];
        console.log("params=====", params);
        console.log("this.useTimePageRecords=====", this.useTimePageRecords);
        console.log("yIndex====", yIndex);

        this.staffCode = this.useTimePageRecords[yIndex].staffCode;
        this.staffName = this.useTimePageRecords[yIndex].staffName;
        console.log("staffCode=====", this.staffCode);
        // this.xAxisData = [];
        this.allLoading = true;
        try {
          await this.getEchartDetailBarData();
        } catch (error) {
          //
        }
        this.allLoading = false;
        this.dialogStackVisible = true;
      }
    },

    /**
     * 加载数据
     */
    getData() {
      let { otherParams } = this;
      if (!(otherParams.startDate && otherParams.endDate)) return; // 无日期参数不调用
      this.isShowEchartBar ? this.getEchartBarData() : this.getPagingData();
    },
  },
};
</script>

<style lang="less" scoped>
.prepare-rank {
  padding-bottom: 20px;
  .details-time {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-left: 20px;
    text-align: left;
  }
  /deep/ .details-custom {
    border-radius: 10px;
    .el-dialog__header {
      padding: 16px 20px 14px;
      font-size: 20px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #131313;
      text-align: left;
    }

    .el-dialog__body {
      padding: 0px 0px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
  }

  /deep/ .el-button:hover,
  .el-button:focus {
    // background-color: inherit;
  }

  /deep/ .el-button--text {
    color: #6049ff;
    box-shadow: inherit;
    border: 0px solid #e4e7ec;
    & :hover {
      color: #6049ff;
      box-shadow: inherit;
      border: 0px solid #e4e7ec;
    }
  }
}
.prepare-header {
  padding-top: 22px;
}
.rank-btns {
}
.echarts-warpper {
  max-width: 1134px;
  margin: 0 auto;
}
.echarts {
  width: 100%;
  margin: 0 auto;
  height: 565px;
}

// 修改element ui 样式
.el-table {
  width: auto;
  margin: 25px 201px 0;
}
.el-table /deep/ th {
  color: #323232;
  text-align: center;
}
.el-table /deep/ td {
  text-align: center;
  color: #707276;
}
// 修改头部背景色
.el-table /deep/ .el-table__header th {
  background: #f5f6f7;
}
.el-pagination {
  background: #f5f6f7;
  padding: 6px 8px 6px 0;
  text-align: right;
  color: #282828;
  font-weight: 400;
  font-size: 12px;
  margin: 0 201px;
  border: 1px solid #ebeef5;
  border-top: none;
  margin-bottom: 17px;
}
.el-pagination /deep/ button {
  background: #f5f6f7;
}
.el-pagination /deep/ .el-pager li {
  background: #f5f6f7;
}
.el-pagination /deep/ .el-pagination__total {
  color: #282828;
}
.el-pagination .pageTotal {
  background: #f5f6f7;
  margin-right: -14px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.choose-type {
  margin-right: 28px;
}
</style>
