<template>
  <main class="pie-nest">
    <header class="pie-nest-title">{{ title }}</header>
    <section class="echarts-warpper">
      <e-charts ref="echarts" :options="options"></e-charts>
    </section>
  </main>
</template>

<script>
import "echarts/lib/chart/pie";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";

// 透明度列表
const opacityList = [1, 0.82, 0.75, 0.69, 0.58, 0.51, 0.42, 0.32, 0.2];
export default {
  name: "pie-nest",
  props: {
    /**
     * 标题
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * 色值表
     */
    colorValueTable: {
      type: Array,
      default: () => [
        [73, 124, 240],
        [84, 195, 54],
        [111, 138, 163],
        [255, 158, 53],
        [248, 140, 116],
        [151, 86, 255],
      ],
    },
    /**
     * 内层数据
     */
    innerData: {
      type: Array,
      default: () => [],
    },
    /**
     * 外层数据
     */
    outerData: {
      type: Array,
      default: () => [],
    },
    /**
     * 图例数据
     */
    legendData: {
      type: Array,
      default: () => [],
    },
    /**
     * series标题
     */
    seriesTitle: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {};
  },
  computed: {
    /**
     * 配置
     */
    options() {
      return {
        title: {
          text:
            this.$t("el.dataAnalysis.unit") +
            this.$t("el.symbol.colon") +
            this.$t("el.MyHome.minutes"),
          top: 5,
          left: 52,
          textStyle: {
            fontSize: 12,
            color: "#8C8C8C",
            fontWeight: 400,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          data: this.legendData,
          selectedMode: false, // 不可选择
          bottom: 0,
          itemWidth: 9, // 元素方块宽度
          itemHeight: 9, // 元素方块高度
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        },
        series: [
          {
            name: this.seriesTitle,
            type: "pie",
            selectedMode: "single",
            radius: [0, "46%"],
            label: {
              position: "inner",
              fontSize: 15,
              lineHeight: 21,
              formatter({ data }) {
                // return `${data.name}\n${data.value}`
                return "";
              },
            },
            labelLine: {
              show: false,
            },
            data: Array.isArray(this.innerData) ? this.innerData : [],
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: this.colorValueTable
                ? (params) => {
                    let colorArr = this.colorValueTable[params.dataIndex];
                    colorArr = Array.isArray(colorArr)
                      ? colorArr
                      : this.colorValueTable[0] || [];
                    colorArr = [].slice.call(colorArr, 0);
                    colorArr.push(0.7);
                    return `rgba(${colorArr.join(",")})`;
                  }
                : "auto",
            },
          },
          {
            name: this.seriesTitle,
            type: "pie",
            radius: ["56%", "72%"],
            label: {
              // alignTo: 'labelLine',
              formatter: "{b|{b}：}{c}  {per|{d}%}  ",
              // formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
              backgroundColor: "#F5F6F7",
              borderColor: "#EBEBEB",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: "#999",
                  lineHeight: 22,
                  align: "center",
                },
                hr: {
                  borderColor: "#EBEBEB",
                  width: "100%",
                  borderWidth: 0.5,
                  height: 0,
                },
                b: {
                  fontSize: 14,
                  lineHeight: 28,
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#646C95",
                  padding: [0, 5, 2, 5],
                  borderRadius: 3,
                  fontSize: 12,
                },
              },
            },
            labelLine: {
              length2: 80,
            },
            minShowLabelAngle: 10,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: this.colorValueTable
                ? ({ data }) => {
                    let colorArr = this.colorValueTable[data.parentIndex];
                    colorArr = Array.isArray(colorArr)
                      ? colorArr
                      : this.colorValueTable[0] || [];
                    colorArr = [].slice.call(colorArr, 0);
                    colorArr.push(opacityList[data.orderNo || 0] || 0.5);
                    return `rgba(${colorArr.join(",")})`;
                  }
                : "auto",
            },
            data: Array.isArray(this.outerData) ? this.outerData : [],
          },
        ],
      };
    },
  },
  mounted() {
    // 绑定事件
    window.addEventListener("resize", this.onResize);
    this.$on("hook:destroyed", () => {
      window.removeEventListener("resize", this.onResize);
    });
  },
  methods: {
    /**
     * 窗口变化
     * 重绘echarts
     */
    onResize() {
      this.$nextTick(() => {
        this.$refs.echarts &&
          this.$refs.echarts.chart &&
          this.$refs.echarts.chart.resize();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pie-nest {
  margin-bottom: 50px;
}
.echarts-warpper {
  max-width: 1134px;
  margin: 0 auto;
}
.echarts {
  width: 100%;
  height: 75vh;
}
.pie-nest-title {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: #464646;
  line-height: 21px;
  padding: 10px 0;
}
</style>
