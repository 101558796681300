<template>
  <div class="prepare-rank">
    <div class="echarts-warpper">
      <e-charts
        v-if="xAxisData.length"
        ref="echarts"
        class="echarts"
        :options="options"
      ></e-charts>
      <not-data v-else></not-data>
    </div>
  </div>
</template>

<script>
import "echarts/lib/chart/bar";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import notDataVue from "../not-data";

let ajaxUid = 0;

export default {
  name: "bar-stack",
  components: {
    [notDataVue.name]: notDataVue, // 无数据
  },
  props: {
    /**
     * 数据
     */
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rankLastLoadId: 0, // 排名加载数据最后一次调用id
      pagingLastLoadId: 0, // 分页加载数据最后一次调用id
      pagingParams: {
        // 分页参数
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0, // 总记录数
    };
  },
  computed: {
    // 图表配置
    options() {
      let self = this;
      console.log("options===", this.data);
      let {
        avgAllTime, // 全校日均备授课时间
        avgPrepareTime, // 全校日均备课时间
        avgTeachTime, // 全校日均授课时间
        xAxisData, // y轴老师数据列表
        prepareYAxisData, // 备课日均时间数据
        teachingYAxisData, // 授课日均时间数据
      } = this.data;

      // this.$nextTick(() => {
      //   console.log("this.$refs.echarts===", this.$refs["echarts"]);
      //   this.$refs["echarts"] &&
      //     this.$refs["echarts"].chart.on("click", this.onHandleChart);
      // });
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["备课时长", "授课时长"],
          selectedMode: true, // 不可选择
          itemWidth: 8, // 元素方块宽度
          itemHeight: 8, // 元素方块高度
          // bottom: 11, // 设置底部
          icon: "circle",
          textStyle: {
            // 文笔样式
            color: "#8C8C8C",
            fontSize: 12,
            lineHeight: 17,
          },
        },
        grid: {
          left: 49,
          right: 63,
          bottom: 58,
          top: 59,
          containLabel: true,
        },
        xAxis: {
          // type: "value",
          type: "category",
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(90, 83, 245, 1)", // 刻度线颜色
              width: 2,
            },
          },
          data: xAxisData,
          axisTick: {
            // 刻度线配置
            show: true, // 隐藏刻度
            alignWithLabel: true, // 刻度与标签对齐
          },
          axisLabel: {
            // 元素文字颜色
            fontSize: 12,
            color: "rgba(51, 51, 51, 1)",
          },
        },
        dataZoom: [
          {
            show: xAxisData.length > 19, // 是否显示
            type: "slider", // slider表示有滑动块的，inside表示内置的
            // start: 1,      // 左边在 10% 的位置。
            // end: 100 ,
            height: "20",
            dataBackground: {
              lineStyle: {
                color: "rgba(14, 219, 219, 0.25)",
              },
              areaStyle: { color: "rgba(14, 219, 219, 0.25)" },
            },
            fillerColor: "rgba(14,219,219,0.2)",
            filterMode: "empty",
            // width: "90%",

            // right: "1%",
            // left: "1%",
            bottom: "0px",
            zoomLock: false, // 是否锁定选择区域（或叫做数据窗口）的大小。如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
            backgroundColor: "#D6D6D6", // 滚到颜色
            // fillerColor: '#1890ff',//滑块颜色
            handeSize: 0, // 手柄
            realtime: true, // 实时更新
            // filter过滤掉窗口外的数据，none不过滤数据，只改变数轴范围
            // filterMode: "filter",
            // 展示10个柱子
            startValue: 0, // 从0个柱子开始，也就是最起始的地方
            endValue: 19, // 到第6个柱子结束
            // xAxisIndex:[0],
            // show: true,
          },
        ],
        yAxis: {
          name: "单位：分钟",
          nameTextStyle: {
            color: "#333333",
            fontWeight: 400,
            fontFamily: "ABBvoice_WCNSG_Rg",
            fontSize: 16,
            padding: [-25, 0, 0, -20],
            verticalAlign: "top",
          },
          // type: "category",
          type: "value",
          // data: xAxisData,
          axisLine: {
            // 水平线配置
            show: false, // 隐藏
          },
          splitLine: {
            // 分割线
            show: true,
            lineStyle: {
              color: "rgba(245, 245, 245, 1)", // 刻度线颜色
              lineHeight: 2,
              width: 2,
            },
          },
          axisTick: {
            // 刻度线配置
            show: false, // 隐藏刻度
          },
          axisLabel: {
            // 元素文字颜色
            fontSize: 16,
            color: "rgba(51, 51, 51, 1)",
            // formatter: (value, index) => {
            //   // 1小时=3600秒
            //   return value.staffName;
            // },
          },
        },
        series: [
          {
            name: "备课时长",
            type: "bar",
            stack: "总量",
            label: {
              // 文字配置
              show: true,
              offset: [0, -20], // 位置偏移
              color: "#728CA5",
              lineHeight: 14,
            },
            barWidth: 10,
            data: prepareYAxisData,
            // markLine: {
            //   // 水平线
            //   symbol: "none", // 去掉箭头
            //   label: "全校日均线",
            //   lineStyle: {
            //     // 线颜色
            //     color: "#6F8AA3",
            //   },
            //   data: [
            //     {
            //       xAxis: avgAllTime,
            //       label: {
            //         position: "start",
            //         distance: 15,
            //         formatter: function () {
            //           return `全校平均备课时长：${avgPrepareTime}\n全校平均授课时长：${avgTeachTime}`;
            //         },
            //       },
            //     },
            //   ],
            // },
            itemStyle: {
              // 柱状样式
              color: "#7FA3F4",
            },
          },
          {
            name: "授课时长",
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              offset: [0, -20], // 位置偏移
              color: "#728CA5",
              lineHeight: 14,
            },
            data: teachingYAxisData,
            itemStyle: {
              color: "#B688FF",
            },
          },
        ],
      };
    },
    /**
     * x轴数据
     */
    xAxisData() {
      return (
        (this.data &&
          Array.isArray(this.data.xAxisData) &&
          this.data.xAxisData) ||
        []
      );
    },
  },
  watch: {},
  mounted() {
    // 绑定事件
    window.addEventListener("resize", this.onResize);
    this.$on("hook:destroyed", () => {
      window.removeEventListener("resize", this.onResize);
    });
  },
  methods: {
    /**
     * 窗口变化
     * 重绘echarts
     */
    onResize() {
      if (!this.isShowEchartBar) return;
      this.$nextTick(() => {
        this.$refs.echarts &&
          this.$refs.echarts.chart &&
          this.$refs.echarts.chart.resize();
      });
    },
    onHandleChart(params) {
      // 获取自定义变量barIds的值,barIds要和option的series里自定义的一样
      // alert(option.series[param.seriesIndex].barIds[param.dataIndex]);
      console.log("params======", params);

      this.$emit("onHandleChart", params);
    },
  },
};
</script>

<style lang="less" scoped>
.prepare-rank {
  padding-bottom: 20px;
}
.prepare-header {
  padding-top: 22px;
}
.rank-btns {
}
.echarts-warpper {
  max-width: 1134px;
  margin: 0 auto;
}
.echarts {
  width: 100%;
  margin: 0 auto;
  height: 68vh;
}

// 修改element ui 样式
.el-table {
  width: auto;
  margin: 25px 201px 0;
}
.el-table /deep/ th {
  color: #323232;
  text-align: center;
}
.el-table /deep/ td {
  text-align: center;
  color: #707276;
}
// 修改头部背景色
.el-table /deep/ .el-table__header th {
  background: #f5f6f7;
}
.el-pagination {
  background: #f5f6f7;
  padding: 6px 8px 6px 0;
  text-align: right;
  color: #282828;
  font-weight: 400;
  font-size: 12px;
  margin: 0 201px;
  border: 1px solid #ebeef5;
  border-top: none;
  margin-bottom: 17px;
}
.el-pagination /deep/ button {
  background: #f5f6f7;
}
.el-pagination /deep/ .el-pager li {
  background: #f5f6f7;
}
.el-pagination /deep/ .el-pagination__total {
  color: #282828;
}
.el-pagination .pageTotal {
  background: #f5f6f7;
  margin-right: -14px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.choose-type {
  margin-right: 28px;
}
</style>
