<template>
  <div>
    <el-radio-group size="small" v-model="chooseTypeData">
      <el-radio-button
        v-for="(item, index) in chooseOptions"
        :key="index"
        :label="item.label"
        >{{ item.value }}</el-radio-button
      >
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: "chooseType",
  props: {
    chooseOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    chooseType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chooseTypeData: this.chooseType,
    };
  },
  watch: {
    chooseType() {
      this.chooseTypeData = this.chooseType;
    },
    chooseTypeData() {
      this.$emit("changeChooseType", this.chooseTypeData);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-radio-group {
  border-radius: 15px;
  background-color: #f5f6f7;
}
/deep/ .el-radio-button__inner {
  background-color: #f5f6f7;
  border: 0;
}
/deep/ .el-radio-button--small .el-radio-button__inner {
  padding: 0;
  font-size: 13px;
  color: #464646;
  line-height: 17px;
  padding: 5px 18px;
  box-sizing: border-box;
  border-radius: 15px;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #6049ff;
}
</style>
