<template>
  <div class="grade-subject-analyze">
    <analysis-title before-icon="icon-tit-pie">
      {{ useTypeNameTitle }}
      <div slot="content">
        <p v-for="(item, index) in useTypeNameContent" :key="index">
          {{ item }}
        </p>
      </div>
    </analysis-title>
    <div v-loading="lastLoadId">
      <template v-if="data.length">
        <pie-nest
          v-for="(gradeItem, index) in data"
          :key="index"
          :seriesTitle="useTypeNameTime"
          :title="gradeItem.levelName"
          :inner-data="gradeItem.gradeData"
          :outer-data="gradeItem.subjectData"
          :legend-data="gradeItem.legendData"
          :color-value-table="getColorValueTable(useType, gradeItem.levelName)"
        >
        </pie-nest>
      </template>
      <not-data v-else></not-data>
    </div>
  </div>
</template>

<script>
import pieNestVue from "@/components/teaching-components-chart/pie-nest/index.vue";
import analysisTitleVue from "@/components/teaching-components-chart/analysis-title/index.vue";
import notDataVue from "@/components/teaching-components-chart/not-data/index.vue";

let ajaxUid = 0;
export default {
  name: "grade-subject-analyze",
  components: {
    [pieNestVue.name]: pieNestVue, // 饼状双环图表
    [analysisTitleVue.name]: analysisTitleVue, // 分析标题
    [notDataVue.name]: notDataVue, // 无数据
  },
  props: {
    /**
     * 其他查询参数
     */
    otherParams: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 备授课类型
     * 0备课、1授课,默认：备课
     */
    useType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: [], // 数据
      lastLoadId: false, // 加载数据中
    };
  },
  computed: {
    /**
     * 类型名称
     */
    useTypeNameTitle() {
      return this.useType === 1
        ? this.$t("el.dataAnalysis.teachingAnalysisEachGrade")
        : this.$t("el.dataAnalysis.preparationAnalysisEachGrade");
    },
    useTypeNameContent() {
      return this.useType === 1
        ? this.$t("el.dataAnalysis.teachingAnalysisEachGradeDesc")
        : this.$t("el.dataAnalysis.preparationAnalysisEachGradeDesc");
    },
    useTypeNameTime() {
      return this.useType === 1
        ? this.$t("el.dataAnalysis.teachingTime")
        : this.$t("el.dataAnalysis.preparingTime");
    },
  },
  watch: {
    /**
     * 其他参数
     */
    otherParams: {
      handler(newVal, oldVal) {
        console.log("otherParams=====", newVal);
        console.log("getData=====", this.$store.state.otherParams);

        this.getData();
      },
      deep: true,
    },
  },
  created() {
    if (this.otherParams.startDate && this.otherParams.endDate) {
      this.getData();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      this.lastLoadId = ajaxUid++;
      let uid = this.lastLoadId;
      this.$axios
        .get("/bi/detailUse/grade/useTime", {
          params: {
            useType: this.useType,
            ...this.otherParams,
          },
        })
        .then(({ data }) => {
          // 不是最后一次请求
          if (uid !== this.lastLoadId) return;
          data = (Array.isArray(data) && data) || [];
          data.forEach((gradeItem) => {
            // 年级层级
            let {
              subjectData, // 学科数据
              gradeData, // 年级数据
            } = gradeItem;
            let legendData = []; // 图例数据
            subjectData = (Array.isArray(subjectData) && subjectData) || [];
            gradeData = (Array.isArray(gradeData) && gradeData) || [];

            // 处理数据
            let gradeOrderNoMap = {}; // 年级排序映射关系
            let subjectOrderMap = {}; // 科目排序递增数据
            gradeData = gradeData.map((item, index) => {
              gradeOrderNoMap[item.gradeId] = index;
              legendData.push(item.gradeName);
              return {
                value: item.useTime, // 时长
                name: item.gradeName, // 年级标识
                _data: item,
              };
            });
            subjectData = subjectData.map((item) => {
              // 当前排序位置
              let orderNo = subjectOrderMap[item.gradeId] || 0;
              // 自增长排序
              subjectOrderMap[item.gradeId] = orderNo + 1;
              return {
                value: item.useTime, // 时长
                name: item.subjectName, // subjectName
                parentIndex: gradeOrderNoMap[item.gradeId], // 年级排序
                orderNo: orderNo, // 科目排序
                _data: item,
              };
            });

            gradeItem.gradeData = gradeData;
            gradeItem.subjectData = subjectData;
            gradeItem.legendData = legendData;
          });
          this.data = data;
        })
        .finally(() => {
          this.lastLoadId = null;
        });
    },
    /**
     * 获取颜色值列表
     * @param {*} useType 备授课类型
     * @param {*} gradeLevel 学段
     */
    getColorValueTable(useType, gradeLevel) {
      return ({
        0: {
          // 备课
          小学: [
            [73, 124, 240],
            [84, 195, 54],
            [111, 138, 163],
            [255, 158, 53],
            [248, 140, 116],
            [151, 86, 255],
          ],
          初中: [
            [73, 124, 240],
            [84, 195, 54],
            [111, 138, 163],
            [255, 158, 53],
            [248, 140, 116],
            [151, 86, 255],
          ],
          高中: [
            [73, 124, 240],
            [84, 195, 54],
            [111, 138, 163],
            [255, 158, 53],
            [248, 140, 116],
            [151, 86, 255],
          ],
        },
        1: {
          // 授课
          小学: [
            [255, 158, 53],
            [248, 140, 116],
            [151, 86, 255],
            [73, 124, 240],
            [84, 195, 54],
            [111, 138, 163],
          ],
          初中: [
            [255, 158, 53],
            [248, 140, 116],
            [151, 86, 255],
            [73, 124, 240],
            [84, 195, 54],
            [111, 138, 163],
          ],
          高中: [
            [255, 158, 53],
            [248, 140, 116],
            [151, 86, 255],
            [73, 124, 240],
            [84, 195, 54],
            [111, 138, 163],
          ],
        },
      }[useType] || {})[gradeLevel];
    },
  },
};
</script>

<style lang="less" scoped>
.analysis-title {
  padding-top: 22px;
  padding-bottom: 16x;
}
.grade-subject-analyze {
  padding-bottom: 22px;
}
</style>
