<template>
  <div class="prepare-rank">
    <div class="echarts-warpper">
      <e-charts
        v-if="xAxisData.length"
        ref="echarts"
        :options="options"
        :style="
          'min-height: 300px;height:' + (xAxisData.length * 50 + 70) + 'px'
        "
      ></e-charts>
      <not-data v-else></not-data>
    </div>
  </div>
</template>

<script>
import "echarts/lib/chart/bar";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import notDataVue from "../not-data";

let ajaxUid = 0;

export default {
  name: "bar-rank",
  components: {
    [notDataVue.name]: notDataVue, // 无数据
  },
  props: {
    /**
     * 数据
     */
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rankLastLoadId: 0, // 排名加载数据最后一次调用id
      pagingLastLoadId: 0, // 分页加载数据最后一次调用id
      pagingParams: {
        // 分页参数
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0, // 总记录数
    };
  },
  computed: {
    // 图表配置
    options() {
      let self = this;
      let {
        avgAllTime, // 全校日均备授课时间
        avgPrepareTime, // 全校日均备课时间
        avgTeachTime, // 全校日均授课时间
        xAxisData, // y轴老师数据列表
        prepareYAxisData, // 备课日均时间数据
        teachingYAxisData, // 授课日均时间数据
      } = this.data;
      console.log("xAxisData", xAxisData);

      this.$nextTick(() => {
        console.log("this.$refs.echarts===", this.$refs["echarts"]);
        this.$refs["echarts"] &&
          this.$refs["echarts"].chart.getZr().on("click", this.onHandleChart);
      });

      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: [
            this.$t("el.dataAnalysis.preparationDailyTime"),
            this.$t("el.dataAnalysis.teachingDailyTime"),
          ],
          selectedMode: false, // 不可选择
          itemWidth: 8, // 元素方块宽度
          itemHeight: 8, // 元素方块高度
          bottom: 11, // 设置底部
          textStyle: {
            // 文笔样式
            color: "#8C8C8C",
            fontSize: 12,
            lineHeight: 17,
          },
        },
        grid: {
          left: 49,
          right: 63,
          bottom: 58,
          top: 59,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          splitLine: {
            // 分割线
            color: "rgba(256, 256, 266, 0.15)", // 刻度线颜色
          },
          axisTick: {
            // 刻度线配置
            show: false, // 隐藏刻度
          },
          axisLabel: {
            // 元素文字颜色
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.45)",
          },
        },
        yAxis: {
          name:
            this.$t("el.dataAnalysis.unit") +
            this.$t("el.symbol.colon") +
            this.$t("el.MyHome.minutes"),
          nameTextStyle: {
            color: "#8C8C8C",
            fontWeight: 400,
            align: "right",
          },
          type: "category",
          data: xAxisData,
          axisLine: {
            // 水平线配置
            show: false, // 隐藏
          },
          axisTick: {
            // 刻度线配置
            show: false, // 隐藏刻度
          },
          axisLabel: {
            // 元素文字颜色
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.45)",
          },
        },
        series: [
          {
            name: this.$t("el.dataAnalysis.preparationDailyTime"),
            type: "bar",
            stack: this.$t("el.dataAnalysis.TheTotal"),
            label: {
              // 文字配置
              show: true,
              offset: [0, -12], // 位置偏移
              color: "#728CA5",
              lineHeight: 14,
            },
            barWidth: 10,
            data: prepareYAxisData,
            markLine: {
              // 水平线
              symbol: "none", // 去掉箭头
              label: this.$t("el.dataAnalysis.SchoolAverage"),
              lineStyle: {
                // 线颜色
                color: "#6F8AA3",
              },
              data: [
                {
                  xAxis: avgAllTime,
                  label: {
                    position: "end",
                    distance: 25,
                    padding: [5, 0, 3, 0],
                    lineHeight: 15,
                    formatter: function () {
                      return (
                        self.$t("el.dataAnalysis.preparationTimeOnAverage") +
                        self.$t("el.symbol.colon") +
                        avgPrepareTime +
                        "\n" +
                        self.$t("el.dataAnalysis.teachingTimeOnAverage") +
                        self.$t("el.symbol.colon") +
                        avgTeachTime
                        // "\n"
                      );
                    },
                  },
                },
              ],
            },
            itemStyle: {
              // 柱状样式
              color: "#7FA3F4",
            },
          },
          {
            name: this.$t("el.dataAnalysis.teachingDailyTime"),
            type: "bar",
            stack: this.$t("el.dataAnalysis.TheTotal"),
            label: {
              show: true,
              offset: [0, -12], // 位置偏移
              color: "#728CA5",
              lineHeight: 14,
            },
            data: teachingYAxisData,
            itemStyle: {
              color: "#B688FF",
            },
          },
        ],
      };
    },
    /**
     * x轴数据
     */
    xAxisData() {
      return (
        (this.data &&
          Array.isArray(this.data.xAxisData) &&
          this.data.xAxisData) ||
        []
      );
    },
  },
  watch: {
    "xAxisData.length": {
      handler(newVal, oldVal) {
        console.log("newVal", newVal);
        if (oldVal) {
          this.onResize();
        }
      },
    },
  },
  mounted() {
    // 绑定事件
    window.addEventListener("resize", this.onResize);
    this.$on("hook:destroyed", () => {
      window.removeEventListener("resize", this.onResize);
    });
  },
  methods: {
    /**
     * 窗口变化
     * 重绘echarts
     */
    onResize() {
      console.log("onResize1");
      // if (!this.isShowEchartBar) return;
      console.log("onResize");
      this.$nextTick(() => {
        this.$refs.echarts &&
          this.$refs.echarts.chart &&
          this.$refs.echarts.chart.resize();
      });
    },
    onHandleChart(params) {
      // 获取自定义变量barIds的值,barIds要和option的series里自定义的一样
      // alert(option.series[param.seriesIndex].barIds[param.dataIndex]);
      console.log("params======", params);

      this.$emit("onHandleChart", params, this.$refs["echarts"].chart);
    },
  },
};
</script>

<style lang="less" scoped>
.prepare-rank {
  padding-bottom: 20px;
}
.prepare-header {
  padding-top: 22px;
}
.rank-btns {
}
.echarts-warpper {
  max-width: 1134px;
  margin: 0 auto;
}
.echarts {
  width: 100%;
  margin: 0 auto;
  height: 60vh;
}

// 修改element ui 样式
.el-table {
  width: auto;
  margin: 25px 201px 0;
}
.el-table /deep/ th {
  color: #323232;
  text-align: center;
}
.el-table /deep/ td {
  text-align: center;
  color: #707276;
}
// 修改头部背景色
.el-table /deep/ .el-table__header th {
  background: #f5f6f7;
}
.el-pagination {
  background: #f5f6f7;
  padding: 6px 8px 6px 0;
  text-align: right;
  color: #282828;
  font-weight: 400;
  font-size: 12px;
  margin: 0 201px;
  border: 1px solid #ebeef5;
  border-top: none;
  margin-bottom: 17px;
}
.el-pagination /deep/ button {
  background: #f5f6f7;
}
.el-pagination /deep/ .el-pager li {
  background: #f5f6f7;
}
.el-pagination /deep/ .el-pagination__total {
  color: #282828;
}
.el-pagination .pageTotal {
  background: #f5f6f7;
  margin-right: -14px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.choose-type {
  margin-right: 28px;
}
</style>
